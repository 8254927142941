import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import type { SetStateAction } from 'jotai';
import { placesAtom, placesLatLngsSelector } from './states';
import type { LatLng, Place } from './types';
import { usePlacesAPI } from '@api/fms';
import { useCallback, useState } from 'react';

export const usePlaces = (): {
  places: Place[];
  setPlaces: SetAtom<[SetStateAction<Place[]>], void>;
  placesLatLngs: LatLng[];
} => {
  const [places, setPlaces] = useAtom(placesAtom);
  const placesLatLngs = useAtomValue(placesLatLngsSelector);

  return {
    places,
    setPlaces,
    placesLatLngs,
  };
};

export const useLoadPlaces = () => {
  const { getPlaces } = usePlacesAPI();
  const setPlaces = useSetAtom(placesAtom);
  const [isLoaded, setIsLoaded] = useState(false);

  const loadPlaces = useCallback(async () => {
    const res = await getPlaces();
    setPlaces(res);
    setIsLoaded(true);
  }, [getPlaces, setPlaces]);

  return {
    isLoaded,
    loadPlaces,
  };
};
